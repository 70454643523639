import React, { useState, useEffect } from "react";

const AddCarrierPopUp = props => {
  const [carrierState, setCarrierState] = useState({
    name: "",
    mc: "",
    rating: 0,
    dispatcherName: "",
    dispatcherEmail: "",
    dispatcherPhone: "",
    notes: "",
    origin: "",
    dest: "",
    origin_state: "",
    dest_state: "",
    preferred: [],
    preferredEntrys: 0
  });

  const {
    name,
    mc,
    rating,
    dispatcherName,
    dispatcherEmail,
    dispatcherPhone,
    notes,
    origin,
    dest,
    origin_state,
    dest_state,
    preferred,
  } = carrierState;

  useEffect(() => {
    //Erases state when toggling pop up
    setCarrierState({
      ...carrierState,
      name: "",
      mc: "",
      rating: 0,
      dispatcherName: "",
      dispatcherEmail: "",
      dispatcherPhone: "",
      notes: "",
      origin: "",
      dest: "",
      origin_state: "",
      dest_state: "",
      preferred: [],
      preferredEntrys: 0
    });
  }, []);

  const onChange = e =>
    setCarrierState({ ...carrierState, [e.target.name]: e.target.value });

  const onPost = () => {
    if (name === "") {
      alert("Must include carrier name");
    } else if (rating < 0 || rating > 5) {
      alert("Rating must be between 1-5");
    } else {
      props.add(carrierState);
      props.toggle(18);
    }
  };

  const increasePreferred = () => {
    let newLane = {
      origin: origin,
      dest: dest,
      origin_state: origin_state,
      dest_state: dest_state
    };

    let arrCopy = preferred;

    setCarrierState({
      ...carrierState,
      origin: "",
      dest: "",
      origin_state: "",
      dest_state: "",
      preferred: [...arrCopy, newLane]
    });
  };

  return (
    <div className="fixedPopUp">
      <div className="addCarrierPopUp">
        <div className="newInputRowCarrier inputRow">
          <div>
            <label>Carrier Name</label>
            <input type="text" name="name" value={name} onChange={onChange} />
          </div>
          <div>
            <label>MC</label>
            <input type="text" name="mc" value={mc} onChange={onChange} />
          </div>
          <div>
            <label>Rating (optional)</label>
            <input
              type="number"
              name="rating"
              value={rating}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="newInputRowCarrier inputRow">
          <div>
            <label>Dispatcher (optional)</label>
            <input
              type="text"
              name="dispatcherName"
              value={dispatcherName}
              onChange={onChange}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="text"
              name="dispatcherEmail"
              value={dispatcherEmail}
              onChange={onChange}
            />
          </div>
          <div>
            <label>Phone</label>
            <input
              type="text"
              name="dispatcherPhone"
              value={dispatcherPhone}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="newInputRowCarrier inputRow preferredRow">
          <div className="preferredTag">
            <label>Preferred Lanes</label>
            <div className="preferredLaneDiv">
              {preferred.length !== 0
                ? preferred.map((x, i) => {
                  return (
                    <div>{`${x.origin}, ${x.origin_state} - ${x.dest}, ${x.dest_state}`}</div>
                  );
                })
                : null}
            </div>
          </div>
          <div className="preferredLaneDivInput">
            <label>Origin City</label>
            <input
              type="text"
              name="origin"
              value={origin}
              onChange={onChange}
            />
          </div>
          <div className="preferredLaneDivInput">
            <label>Origin State</label>
            <input
              type="text"
              name="origin_state"
              value={origin_state}
              onChange={onChange}
            />
          </div>
          <div className="preferredLaneDivInput">
            <label>Dest. City</label>
            <input type="text" name="dest" value={dest} onChange={onChange} />
          </div>
          <div className="preferredLaneDivInput">
            <label>Dest. State</label>
            <input
              type="text"
              name="dest_state"
              value={dest_state}
              onChange={onChange}
            />
          </div>
          {preferred.length > 10 ? null : (
            <div className="addBtnPreferred" onClick={increasePreferred}>
              Add
            </div>
          )}
        </div>

        <div className="inputRow5 inputRow">
          <div>
            <label>Notes</label>
            <textarea
              type="text"
              name="notes"
              value={notes}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="btnRow">
          <div className="btnRowFiller"></div>
          <div onClick={() => props.toggle(18)} className="cancelBtn">
            Cancel
          </div>
          <div onClick={onPost} className="postBtn">
            Add Carrier
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCarrierPopUp;
