import React, { useContext, useEffect, useState } from "react";
import AddMasterLoadPopUp from "./pop-ups/AddMasterLoadPopUp";
import LoadContext from "../context/loads/loadContext";
import AuthContext from "../context/auth/authContext";
import FinalizePopUp from "./pop-ups/FinalizePopUp";
import ConfirmDeliveryPopUp from "./pop-ups/ConfirmDeliveryPopUp";
import CarrierFeedbackPopUp from "./pop-ups/CarrierFeedbackPopUp";
import SingleLoadPopUp from "./pop-ups/SingleLoadPopUp";
import FilterPopUp from "./pop-ups/FilterPopUp";
import CalculatePopUp from "./pop-ups/CalculatePopUp";
import DupLoadPopUp from "./pop-ups/DupLoadPopUp";

const MasterList = props => {
  const loadContext = useContext(LoadContext);
  const {
    addMasterLoadPopUp,
    togglePopUp,
    masterList,
    getMasterLoads,
    finalizePopUp,
    afterDeleteCurrent,
    changeSingleLoad,
    markFinished,
    singleLoad,
    submitFeedback,
    markDelivered,
    confirmDeliveryPopUp,
    carrierFeedbackPopUp,
    singleLoadPopUp,
    addLabel,
    editLoad,
    removeOpenLoad,
    markScanned,
    afterDeleteOpen,
    changeIndex,
    filterList,
    clearFilter,
    filterPopUp,
    filter1,
    filter1Value,
    filter2,
    filter2Value,
    filter3,
    filter3Value,
    filter4,
    filter4Value,
    calcPopUp,
    afterFeedback,
    dupPopUp,
    dupOpenLoad
  } = loadContext;
  const authContext = useContext(AuthContext);
  const { admin } = authContext;

  useEffect(() => {
    getMasterLoads();
  }, [afterDeleteCurrent, afterDeleteOpen, afterFeedback]);

  const getTotalRevenue = () => {
    let total = 0;
    if (filter4 !== "") {
      masterList
        .filter(
          x =>
            x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
            x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
            x[filter3].toLowerCase() === filter3Value.toLowerCase() &&
            x[filter4].toLowerCase() === filter4Value.toLowerCase()
        )
        .forEach(x => {
          total += x.gw_rate;
        });
    } else if (filter3 !== "") {
      masterList
        .filter(
          x =>
            x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
            x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
            x[filter3].toLowerCase() === filter3Value.toLowerCase()
        )
        .forEach(x => {
          total += x.gw_rate;
        });
    } else if (filter2 !== "") {
      masterList
        .filter(
          x =>
            x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
            x[filter2].toLowerCase() === filter2Value.toLowerCase()
        )
        .forEach(x => {
          total += x.gw_rate;
        });
    } else if (filter1 !== "") {
      masterList
        .filter(x => x[filter1].toLowerCase() === filter1Value.toLowerCase())
        .forEach(x => {
          total += x.gw_rate;
        });
    } else {
      masterList.forEach(x => {
        total += x.gw_rate;
      });
    }
    return parseFloat(total).toFixed(2);
  };
  const getTotalProfit = () => {
    let total = 0;

    if (filter4 !== "") {
      masterList
        .filter(
          x =>
            x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
            x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
            x[filter3].toLowerCase() === filter3Value.toLowerCase() &&
            x[filter4].toLowerCase() === filter4Value.toLowerCase()
        )
        .forEach(x => {
          total += x.gw_rate;
          total -= x.truck_rate;
        });
    } else if (filter3 !== "") {
      masterList
        .filter(
          x =>
            x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
            x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
            x[filter3].toLowerCase() === filter3Value.toLowerCase()
        )
        .forEach(x => {
          total += x.gw_rate;
          total -= x.truck_rate;
        });
    } else if (filter2 !== "") {
      masterList
        .filter(
          x =>
            x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
            x[filter2].toLowerCase() === filter2Value.toLowerCase()
        )
        .forEach(x => {
          total += x.gw_rate;
          total -= x.truck_rate;
        });
    } else if (filter1 !== "") {
      masterList
        .filter(x => x[filter1].toLowerCase() === filter1Value.toLowerCase())
        .forEach(x => {
          total += x.gw_rate;
          total -= x.truck_rate;
        });
    } else {
      masterList.forEach(x => {
        total += x.gw_rate;
        total -= x.truck_rate;
      });
    }
    return parseFloat(total).toFixed(2);
  };

  return (
    <React.Fragment>
      {finalizePopUp ? (
        <FinalizePopUp
          toggle={togglePopUp}
          markFinished={markFinished}
          load={singleLoad}
        />
      ) : null}
      {confirmDeliveryPopUp ? (
        <ConfirmDeliveryPopUp
          toggle={togglePopUp}
          markDelivered={markDelivered}
          load={singleLoad}
        />
      ) : null}
      {carrierFeedbackPopUp ? (
        <CarrierFeedbackPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          load={singleLoad}
        />
      ) : null}
      {singleLoadPopUp ? (
        <SingleLoadPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          add={editLoad}
          load={singleLoad}
          open={false}
          quote={false}
          remove={removeOpenLoad}
          markScanned={markScanned}
          master={true}
          _admin={admin}
          adminObj={props.adminObj}
          addLabel={addLabel}
        />
      ) : null}
      {filterPopUp ? (
        <FilterPopUp
          toggle={togglePopUp}
          filter={filterList}
          clear={clearFilter}
          source={"master"}
        />
      ) : null}
      {calcPopUp ? <CalculatePopUp /> : null}
      {dupPopUp ? (
        <DupLoadPopUp
          toggle={togglePopUp}
          duplicate={dupOpenLoad}
          load={singleLoad}
        />
      ) : null}
      <div className="toolBarContent">
        <div onClick={() => togglePopUp(12)} id="totals">
          <div>
            Loads:{" "}
            {filter4 !== ""
              ? masterList.filter(
                x =>
                  x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                  x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                  x[filter3].toLowerCase() === filter3Value.toLowerCase() &&
                  x[filter4].toLowerCase() === filter4Value.toLowerCase()
              ).length
              : filter3 !== ""
                ? masterList.filter(
                  x =>
                    x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                    x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                    x[filter3].toLowerCase() === filter3Value.toLowerCase()
                ).length
                : filter2 !== ""
                  ? masterList.filter(
                    x =>
                      x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                      x[filter2].toLowerCase() === filter2Value.toLowerCase()
                  ).length
                  : filter1 !== ""
                    ? masterList.filter(
                      x => x[filter1].toLowerCase() === filter1Value.toLowerCase()
                    ).length
                    : masterList.length}
          </div>
          <div>Revenue: ${getTotalRevenue()}</div>
          <div>Profit: ${getTotalProfit()}</div>
        </div>
        <div onClick={() => togglePopUp(12)} id="search">
          <div>Search work history</div>
        </div>
        <div onClick={() => togglePopUp(10)} id="filter">
          <div>
            <i className="fas fa-filter searchIcon"></i>Filter
          </div>
        </div>
      </div>
      <div className="yearTab">
        <div className="selectedYear">All</div>
        <div onClick={() => (clearFilter(), changeIndex(8))}>2021</div>
        <div onClick={() => (clearFilter(), changeIndex(9))}>2022</div>
        <div onClick={() => (clearFilter(), changeIndex(10))}>2023</div>
        <div onClick={() => (clearFilter(), changeIndex(11))}>2024</div>
        <div onClick={() => (clearFilter(), changeIndex(12))}>2025</div>
      </div>
      <div className="bigCurrentDiv">
        {filter4 !== ""
          ? masterList
            .filter(
              x =>
                x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                x[filter3].toLowerCase() === filter3Value.toLowerCase() &&
                x[filter4].toLowerCase() === filter4Value.toLowerCase()
            )
            .map((x, i) => {
              return (
                <div
                  key={i}
                  className={
                    x.stage === 3
                      ? "actualLoad actualMasterLoadPaperWork"
                      : x.stage === 4
                        ? "actualLoad actualMasterLoad"
                        : "actualLoad actualCurrentLoad"
                  }
                  onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                >
                  <div className="titleActualLoad">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                  <div className="secondAttribute">{x.g}</div>
                  <div className="firstAttribute">
                    {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                  </div>
                  <div className="secondAttribute">{x.customer}</div>
                  {x.rating ? (
                    x.stage === 3 || x.stage === 4 ? (
                      <div className="secondAttribute">{`${x.carrier} - ${x.rating}`}</div>
                    ) : (
                      <div className="secondAttribute">{x.carrier}</div>
                    )
                  ) : (
                    <div className="secondAttribute">{x.carrier}</div>
                  )}
                  <div className="thirdAttribute">
                    ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                    <span>({(x.margin * 100).toFixed(0)}%)</span>
                  </div>
                  <div className="fourthAttribute">
                    {x.stage === 4 ? (
                      <i className="fas fa-check-circle checkCircle"></i>
                    ) : (
                      <i
                        onClick={
                          x.stage === 3
                            ? () => (
                              togglePopUp(8),
                              changeSingleLoad(x),
                              togglePopUp(9)
                            )
                            : x.stage == 2
                              ? () => (
                                togglePopUp(6),
                                changeSingleLoad(x),
                                togglePopUp(9)
                              )
                              : null
                        }
                        className="far fa-check-circle checkCircle"
                      ></i>
                    )}
                  </div>
                </div>
              );
            })
          : filter3 !== ""
            ? masterList
              .filter(
                x =>
                  x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                  x[filter2].toLowerCase() === filter2Value.toLowerCase() &&
                  x[filter3].toLowerCase() === filter3Value.toLowerCase()
              )
              .map((x, i) => {
                return (
                  <div
                    key={i}
                    className={
                      x.stage === 3
                        ? "actualLoad actualMasterLoadPaperWork"
                        : x.stage === 4
                          ? "actualLoad actualMasterLoad"
                          : "actualLoad actualCurrentLoad"
                    }
                    onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                  >
                    <div className="titleActualLoad">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                    <div className="secondAttribute">{x.g}</div>
                    <div className="firstAttribute">
                      {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                    </div>
                    <div className="secondAttribute">{x.customer}</div>
                    {x.rating ? (
                      x.stage === 3 || x.stage === 4 ? (
                        <div className="secondAttribute">{`${x.carrier} - ${x.rating}`}</div>
                      ) : (
                        <div className="secondAttribute">{x.carrier}</div>
                      )
                    ) : (
                      <div className="secondAttribute">{x.carrier}</div>
                    )}
                    <div className="thirdAttribute">
                      ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                      <span>({(x.margin * 100).toFixed(0)}%)</span>
                    </div>
                    <div className="fourthAttribute">
                      {x.stage === 4 ? (
                        <i className="fas fa-check-circle checkCircle"></i>
                      ) : (
                        <i
                          onClick={
                            x.stage === 3
                              ? () => (
                                togglePopUp(8),
                                changeSingleLoad(x),
                                togglePopUp(9)
                              )
                              : x.stage == 2
                                ? () => (
                                  togglePopUp(6),
                                  changeSingleLoad(x),
                                  togglePopUp(9)
                                )
                                : null
                          }
                          className="far fa-check-circle checkCircle"
                        ></i>
                      )}
                    </div>
                  </div>
                );
              })
            : filter2 !== ""
              ? masterList
                .filter(
                  x =>
                    x[filter1].toLowerCase() === filter1Value.toLowerCase() &&
                    x[filter2].toLowerCase() === filter2Value.toLowerCase()
                )
                .map((x, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        x.stage === 3
                          ? "actualLoad actualMasterLoadPaperWork"
                          : x.stage === 4
                            ? "actualLoad actualMasterLoad"
                            : "actualLoad actualCurrentLoad"
                      }
                      onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                    >
                      <div className="titleActualLoad">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="secondAttribute">{x.g}</div>
                      <div className="firstAttribute">
                        {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                      </div>
                      <div className="secondAttribute">{x.customer}</div>
                      {x.rating ? (
                        x.stage === 3 || x.stage === 4 ? (
                          <div className="secondAttribute">{`${x.carrier} - ${x.rating}`}</div>
                        ) : (
                          <div className="secondAttribute">{x.carrier}</div>
                        )
                      ) : (
                        <div className="secondAttribute">{x.carrier}</div>
                      )}
                      <div className="thirdAttribute">
                        ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                        <span>({(x.margin * 100).toFixed(0)}%)</span>
                      </div>
                      <div className="fourthAttribute">
                        {x.stage === 4 ? (
                          <i className="fas fa-check-circle checkCircle"></i>
                        ) : (
                          <i
                            onClick={
                              x.stage === 3
                                ? () => (
                                  togglePopUp(8),
                                  changeSingleLoad(x),
                                  togglePopUp(9)
                                )
                                : x.stage == 2
                                  ? () => (
                                    togglePopUp(6),
                                    changeSingleLoad(x),
                                    togglePopUp(9)
                                  )
                                  : null
                            }
                            className="far fa-check-circle checkCircle"
                          ></i>
                        )}
                      </div>
                    </div>
                  );
                })
              : filter1 !== ""
                ? masterList
                  .filter(
                    x => x[filter1].toLowerCase() === filter1Value.toLowerCase()
                  )
                  .map((x, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          x.stage === 3
                            ? "actualLoad actualMasterLoadPaperWork"
                            : x.stage === 4
                              ? "actualLoad actualMasterLoad"
                              : "actualLoad actualCurrentLoad"
                        }
                        onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                      >
                        <div className="titleActualLoad">
                          {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                        </div>
                        <div className="secondAttribute">{x.g}</div>
                        <div className="firstAttribute">
                          {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                        </div>
                        <div className="secondAttribute">{x.customer}</div>
                        {x.rating ? (
                          x.stage === 3 || x.stage === 4 ? (
                            <div className="secondAttribute">{`${x.carrier} - ${x.rating}`}</div>
                          ) : (
                            <div className="secondAttribute">{x.carrier}</div>
                          )
                        ) : (
                          <div className="secondAttribute">{x.carrier}</div>
                        )}
                        <div className="thirdAttribute">
                          ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                          <span>({(x.margin * 100).toFixed(0)}%)</span>
                        </div>
                        <div className="fourthAttribute">
                          {x.stage === 4 ? (
                            <i className="fas fa-check-circle checkCircle"></i>
                          ) : (
                            <i
                              onClick={
                                x.stage === 3
                                  ? () => (
                                    togglePopUp(8),
                                    changeSingleLoad(x),
                                    togglePopUp(9)
                                  )
                                  : x.stage == 2
                                    ? () => (
                                      togglePopUp(6),
                                      changeSingleLoad(x),
                                      togglePopUp(9)
                                    )
                                    : null
                              }
                              className="far fa-check-circle checkCircle"
                            ></i>
                          )}
                        </div>
                      </div>
                    );
                  })
                : masterList.map((x, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        x.stage === 3
                          ? "actualLoad actualMasterLoadPaperWork"
                          : x.stage === 4
                            ? "actualLoad actualMasterLoad"
                            : "actualLoad actualCurrentLoad"
                      }
                      onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                    >
                      <div className="titleActualLoad">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="secondAttribute">{x.g}</div>
                      <div className="firstAttribute">
                        {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                      </div>
                      {x.rating ? (
                        x.stage === 3 || x.stage === 4 ? (
                          <div className="secondAttribute">{`${x.carrier} - ${x.rating}`}</div>
                        ) : (
                          <div className="secondAttribute">{x.carrier}</div>
                        )
                      ) : (
                        <div className="secondAttribute">{x.carrier}</div>
                      )}
                      <div className="thirdAttribute">
                        ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                        <span>({(x.margin * 100).toFixed(0)}%)</span>
                      </div>
                      <div className="fourthAttribute">
                        {x.stage === 4 ? (
                          <i className="fas fa-check-circle checkCircle"></i>
                        ) : (
                          <i
                            onClick={
                              x.stage === 3
                                ? () => (
                                  togglePopUp(8),
                                  changeSingleLoad(x),
                                  togglePopUp(9)
                                )
                                : x.stage == 2
                                  ? () => (
                                    togglePopUp(6),
                                    changeSingleLoad(x),
                                    togglePopUp(9)
                                  )
                                  : null
                            }
                            className="far fa-check-circle checkCircle"
                          ></i>
                        )}
                      </div>
                    </div>
                  );
                })}
      </div>
    </React.Fragment>
  );
};

export default MasterList;