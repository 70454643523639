import React, { useContext, useEffect } from "react";
import LoadContext from "../context/loads/loadContext";
import ConfirmDeliveryPopUp from "./pop-ups/ConfirmDeliveryPopUp";
import CarrierFeedbackPopUp from "./pop-ups/CarrierFeedbackPopUp";
import SingleLoadPopUp from "./pop-ups/SingleLoadPopUp";
import FinalizePopUp from "./pop-ups/FinalizePopUp";
import { CSVLink } from "react-csv";

const NCLoads = props => {
  const loadContext = useContext(LoadContext);
  const {
    togglePopUp,
    getNCLoads,
    afterDeleteCurrent,
    NCLoadList,
    confirmDeliveryPopUp,
    changeSingleLoad,
    markDelivered,
    carrierFeedbackPopUp,
    submitFeedback,
    afterFeedback,
    singleLoad,
    singleLoadPopUp,
    editLoad,
    removeOpenLoad,
    markScanned,
    afterDeleteOpen,
    check,
    addLabel,
    finalizePopUp,
    markFinished,
    getScansCSV,
    scannedLoadsCSV
  } = loadContext;

  useEffect(() => {
    getNCLoads();
    getScansCSV();
  }, [afterDeleteCurrent, afterFeedback, afterDeleteOpen]);

  return (
    <React.Fragment>
      {finalizePopUp ? (
        <FinalizePopUp
          toggle={togglePopUp}
          markFinished={markFinished}
          load={singleLoad}
        />
      ) : null}
      {confirmDeliveryPopUp ? (
        <ConfirmDeliveryPopUp
          toggle={togglePopUp}
          markDelivered={markDelivered}
          load={singleLoad}
        />
      ) : null}
      {carrierFeedbackPopUp ? (
        <CarrierFeedbackPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          load={singleLoad}
        />
      ) : null}
      {singleLoadPopUp ? (
        <SingleLoadPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          add={editLoad}
          load={singleLoad}
          open={false}
          nearlyComplete={true}
          quote={false}
          remove={removeOpenLoad}
          markScanned={markScanned}
          master={false}
          _admin={{ name: "nope" }}
          adminObj={props.adminObj}
          addLabel={addLabel}
        />
      ) : null}
      <div className="toolBarContent">
        {/* <div id="filter">
          <div>
            <i className="fas fa-filter searchIcon"></i>Filter
          </div>
        </div> */}
        <CSVLink
          data={scannedLoadsCSV}
          style={{ textDecoration: "none" }}
          filename={`Invoice Tracking.csv`}
        >
          <div id="addLoad">Export scanned loads</div>
        </CSVLink>
      </div>
      <div className="bigCurrentDiv">
        {NCLoadList.length === 0 ? (
          <h1>No nearly completed loads</h1>
        ) : (
          NCLoadList.map((x, i) => {
            return (
              <div
                key={i}
                className={
                  x.stage === 3
                    ? "actualLoad actualMasterLoadPaperWork"
                    : x.stage === 4
                      ? "actualLoad actualMasterLoad"
                      : "actualLoad actualCurrentLoad"
                }
                onClick={() => (changeSingleLoad(x), togglePopUp(9))}
              >
                {x.scanned_date !== "NA" ? (
                  <div className="titleActualLoadWrapperAuto">
                    <div className="titleActualLoadInner">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                    <div className="titleActualLoadTag">
                      <span
                        style={{ color: "#fff", backgroundColor: "#4271ff" }}
                      >
                        Scanned
                        <i className="fas fa-check regCheck"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="titleActualLoad">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                )}
                <div className="secondAttribute">{x.g}</div>
                <div className="firstAttribute">
                  {`${x.pu_date_display} - `} <span>{`${x.pu_time}`}</span>
                </div>
                {x.rating ? (
                  x.stage === 3 || x.stage === 4 ? (
                    <div className="secondAttribute">{`${x.carrier} - ${x.rating}`}</div>
                  ) : (
                    <div className="secondAttribute">{x.carrier}</div>
                  )
                ) : (
                  <div className="secondAttribute">{x.carrier}</div>
                )}
                <div className="thirdAttribute">
                  ${parseFloat(x.gw_rate - x.truck_rate).toFixed(2)}{" "}
                  <span>({(x.margin * 100).toFixed(0)}%)</span>
                </div>
                <div className="fourthAttribute">
                  {x.stage === 4 ? (
                    <i className="fas fa-check-circle checkCircle"></i>
                  ) : (
                    <i
                      onClick={
                        x.stage === 3
                          ? () => (
                            togglePopUp(8),
                            changeSingleLoad(x),
                            togglePopUp(9)
                          )
                          : x.stage == 2
                            ? () => (
                              togglePopUp(6),
                              changeSingleLoad(x),
                              togglePopUp(9)
                            )
                            : null
                      }
                      className="far fa-check-circle checkCircle"
                    ></i>
                  )}
                </div>
              </div>
            );
          })
        )}
      </div>
    </React.Fragment>
  );
};

export default NCLoads;
