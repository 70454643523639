import React, { useContext, useEffect, useState } from "react";
import LoadContext from "../context/loads/loadContext";
import LanePopUp from "./pop-ups/LanePopUp";
import AddCommentPopUp from "./pop-ups/AddCommentPopUp";
import AddCarrierPopUp from "./pop-ups/AddCarrierPopUp";
import DeleteCarrierPopUp from "./pop-ups/DeleteCarrierPopUp";
import SingleCarrierPopUp from "./pop-ups/SingleCarrierPopUp";

const CarrierList = () => {
  const loadContext = useContext(LoadContext);
  const {
    getCarriers,
    carrierList,
    togglePopUp,
    lanePopUp,
    addCommentPopUp,
    submitComment,
    changeSingleCarrier,
    singleCarrier,
    afterFeedback,
    addCarrierPopUp,
    addCarrier,
    deleteCarrierPopUp,
    deleteCarrier,
    singleCarrierPopUp,
    editCarrier
  } = loadContext;

  const [filt, setFilter] = useState({
    number: "",
    dispatcher_name: "",
    origin: "",
    dest: ""
  });

  const { number, dispatcher_name, origin, dest } = filt;

  const onChange = e => setFilter({ ...filt, [e.target.name]: e.target.value });

  useEffect(() => {
    getCarriers();
  }, [afterFeedback]);

  return (
    <React.Fragment>
      <div className="toolBarContent">
        <div id="filterMC">
          <input
            type="text"
            name="number"
            value={number}
            onChange={onChange}
            placeholder="Search MC"
          />
        </div>
        <div id="filterDispatcher">
          <input
            type="text"
            name="dispatcher_name"
            value={dispatcher_name}
            onChange={onChange}
            placeholder="Dispatcher"
          />
        </div>
        <div id="filterLane">
          <input
            type="text"
            name="origin"
            value={origin}
            onChange={onChange}
            placeholder="Origin"
          />
        </div>
        <div id="filterLane">
          <input
            type="text"
            name="dest"
            value={dest}
            onChange={onChange}
            placeholder="Destination"
          />
        </div>
        <div onClick={() => togglePopUp(13)} id="filterLanes">
          Email List
        </div>
        <div onClick={() => togglePopUp(18)} id="addCarrier">
          Add Carrier
        </div>
        {/* <div id="searchCarrier">
          <div>Search Past Origins & Destinations</div>
        </div> */}
      </div>
      {lanePopUp ? <LanePopUp /> : null}
      {addCommentPopUp ? (
        <AddCommentPopUp
          toggle={togglePopUp}
          submitComment={submitComment}
          carrier={singleCarrier}
        />
      ) : null}
      {addCarrierPopUp ? (
        <AddCarrierPopUp add={addCarrier} toggle={togglePopUp} />
      ) : null}
      {singleCarrierPopUp ? (
        <SingleCarrierPopUp
          edit={editCarrier}
          toggle={togglePopUp}
          carrier={singleCarrier}
          deleteCarrier={deleteCarrier}
        />
      ) : null}
      {deleteCarrierPopUp ? (
        <DeleteCarrierPopUp
          deleteCarrier={deleteCarrier}
          toggle={togglePopUp}
          carrier={singleCarrier}
        />
      ) : null}
      <div className="bigCurrentDiv">
        {number !== ""
          ? carrierList
            .filter(x => x.mc === number)
            .map((x, i) => {
              return (
                <div
                  className={
                    x.rating === 1
                      ? "actualCarrier danger"
                      : x.rating === 2
                        ? "actualCarrier warning"
                        : "actualCarrier normalCarrier"
                  }
                  key={i}
                >
                  <div className="titleSectionDivCarrier">
                    <div className="titleActualLoad">
                      <span style={{ fontSize: "1.2vw" }}>{`${x.name} (${x.rating === 0 ? "NA" : x.rating
                        })`}</span>
                      <br />
                      <span
                        style={{ fontSize: "0.9vw", fontWeight: "400" }}
                      >{`MC: ${x.mc}`}</span>
                    </div>
                    <div className="btns4Carrier">
                      <div className="titleActualLoad loadsBooked">
                        {`${x.loads.length} Loads Booked`}
                      </div>
                      <div
                        className="titleActualLoad editCarrierBtn"
                        onClick={() => (
                          togglePopUp(20), changeSingleCarrier(x)
                        )}
                      >
                        Edit
                      </div>
                    </div>
                  </div>
                  {x.dispatchers.map((y, j) => {
                    return (
                      <div className="listOfContactsDiv" key={j}>
                        {/* <div className="spaceActualLoad"></div> */}
                        <div className="firstAttribute">{y.name}</div>
                        <div className="secondAttribute">{y.phone}</div>
                        <div className="secondAttribute">{y.email}</div>
                      </div>
                    );
                  })}
                  {x.preferred.map((y, j) => {
                    return (
                      <div className="listOfPrefDiv" key={j}>
                        {`${y.origin}, ${y.origin_state} - ${y.dest}, ${y.dest_state}`}
                      </div>
                    );
                  })}
                  <div className="carrierComments">
                    <h1>
                      Logbook{" "}
                      <i
                        onClick={() => (
                          togglePopUp(17), changeSingleCarrier(x)
                        )}
                        className="fas fa-marker"
                      ></i>
                    </h1>
                    {x.notes.length === 0 ? (
                      <h1 style={{ fontSize: "0.9vw" }}>No records</h1>
                    ) : (
                      x.notes.map((z, k) => {
                        return (
                          <div className="singleComment" key={k}>
                            <div className="commentAuthor">{`${z.author} - ${z.date}`}</div>
                            <div className="commentContent">{z.content}</div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              );
            })
          : dispatcher_name !== ""
            ? carrierList.map((x, i) => {
              if (
                x.dispatchers.filter(
                  y =>
                    y.name.toLocaleLowerCase() ===
                    dispatcher_name.toLocaleLowerCase()
                ).length > 0
              ) {
                return (
                  <div
                    className={
                      x.rating === 1
                        ? "actualCarrier danger"
                        : x.rating === 2
                          ? "actualCarrier warning"
                          : "actualCarrier normalCarrier"
                    }
                    key={i}
                  >
                    <div className="titleSectionDivCarrier">
                      <div className="titleActualLoad">
                        <span style={{ fontSize: "1.2vw" }}>{`${x.name} (${x.rating === 0 ? "NA" : x.rating
                          })`}</span>
                        <br />
                        <span
                          style={{ fontSize: "0.9vw", fontWeight: "400" }}
                        >{`MC: ${x.mc}`}</span>
                      </div>
                      <div className="btns4Carrier">
                        <div className="titleActualLoad loadsBooked">
                          {`${x.loads.length} Loads Booked`}
                        </div>
                        <div
                          className="titleActualLoad editCarrierBtn"
                          onClick={() => (
                            togglePopUp(20), changeSingleCarrier(x)
                          )}
                        >
                          Edit
                        </div>
                      </div>
                    </div>
                    {x.dispatchers.map((y, j) => {
                      return (
                        <div className="listOfContactsDiv" key={j}>
                          {/* <div className="spaceActualLoad"></div> */}
                          <div className="firstAttribute">{y.name}</div>
                          <div className="secondAttribute">{y.phone}</div>
                          <div className="secondAttribute">{y.email}</div>
                        </div>
                      );
                    })}
                    {x.preferred.map((y, j) => {
                      return (
                        <div className="listOfPrefDiv" key={j}>
                          {`${y.origin}, ${y.origin_state} - ${y.dest}, ${y.dest_state}`}
                        </div>
                      );
                    })}
                    <div className="carrierComments">
                      <h1>
                        Logbook{" "}
                        <i
                          onClick={() => (
                            togglePopUp(17), changeSingleCarrier(x)
                          )}
                          className="fas fa-marker"
                        ></i>
                      </h1>
                      {x.notes.length === 0 ? (
                        <h1 style={{ fontSize: "0.9vw" }}>No records</h1>
                      ) : (
                        x.notes.map((z, k) => {
                          return (
                            <div className="singleComment" key={k}>
                              <div className="commentAuthor">{`${z.author} - ${z.date}`}</div>
                              <div className="commentContent">{z.content}</div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })
            : origin !== "" && dest !== ""
              ? carrierList.map((x, i) => {
                if (
                  x.preferred.filter(
                    y =>
                      (y.origin === origin || y.origin_state === origin) &&
                      (y.dest === dest || y.dest_state === dest)
                  ).length > 0
                ) {
                  return (
                    <div
                      className={
                        x.rating === 1
                          ? "actualCarrier danger"
                          : x.rating === 2
                            ? "actualCarrier warning"
                            : "actualCarrier normalCarrier"
                      }
                      key={i}
                    >
                      <div className="titleSectionDivCarrier">
                        <div className="titleActualLoad">
                          <span style={{ fontSize: "1.2vw" }}>
                            {`${x.name} (${x.rating === 0 ? "NA" : x.rating})`}
                          </span>
                          <br />
                          <span
                            style={{ fontSize: "0.9vw", fontWeight: "400" }}
                          >{`MC: ${x.mc}`}</span>
                        </div>
                        <div className="btns4Carrier">
                          <div className="titleActualLoad loadsBooked">
                            {`${x.loads.length} Loads Booked`}
                          </div>
                          <div
                            className="titleActualLoad editCarrierBtn"
                            onClick={() => (
                              togglePopUp(20), changeSingleCarrier(x)
                            )}
                          >
                            Edit
                          </div>
                        </div>
                      </div>
                      {x.dispatchers.map((y, j) => {
                        return (
                          <div className="listOfContactsDiv" key={j}>
                            {/* <div className="spaceActualLoad"></div> */}
                            <div className="firstAttribute">{y.name}</div>
                            <div className="secondAttribute">{y.phone}</div>
                            <div className="secondAttribute">{y.email}</div>
                          </div>
                        );
                      })}
                      {x.preferred.map((y, j) => {
                        return (
                          <div className="listOfPrefDiv" key={j}>
                            {`${y.origin}, ${y.origin_state} - ${y.dest}, ${y.dest_state}`}
                          </div>
                        );
                      })}
                      <div className="carrierComments">
                        <h1>
                          Logbook{" "}
                          <i
                            onClick={() => (
                              togglePopUp(17), changeSingleCarrier(x)
                            )}
                            className="fas fa-marker"
                          ></i>
                        </h1>
                        {x.notes.length === 0 ? (
                          <h1 style={{ fontSize: "0.9vw" }}>No records</h1>
                        ) : (
                          x.notes.map((z, k) => {
                            return (
                              <div className="singleComment" key={k}>
                                <div className="commentAuthor">{`${z.author} - ${z.date}`}</div>
                                <div className="commentContent">{z.content}</div>
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
              : origin !== ""
                ? carrierList.map((x, i) => {
                  if (
                    x.preferred.filter(
                      y => y.origin === origin || y.origin_state === origin
                    ).length > 0
                  ) {
                    return (
                      <div
                        className={
                          x.rating === 1
                            ? "actualCarrier danger"
                            : x.rating === 2
                              ? "actualCarrier warning"
                              : "actualCarrier normalCarrier"
                        }
                        key={i}
                      >
                        <div className="titleSectionDivCarrier">
                          <div className="titleActualLoad">
                            <span style={{ fontSize: "1.2vw" }}>{`${x.name} (${x.rating === 0 ? "NA" : x.rating
                              })`}</span>
                            <br />
                            <span
                              style={{ fontSize: "0.9vw", fontWeight: "400" }}
                            >{`MC: ${x.mc}`}</span>
                          </div>
                          <div className="btns4Carrier">
                            <div className="titleActualLoad loadsBooked">
                              {`${x.loads.length} Loads Booked`}
                            </div>
                            <div
                              className="titleActualLoad editCarrierBtn"
                              onClick={() => (
                                togglePopUp(20), changeSingleCarrier(x)
                              )}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                        {x.dispatchers.map((y, j) => {
                          return (
                            <div className="listOfContactsDiv" key={j}>
                              <div className="firstAttribute">{y.name}</div>
                              <div className="secondAttribute">{y.phone}</div>
                              <div className="secondAttribute">{y.email}</div>
                            </div>
                          );
                        })}
                        {x.preferred.map((y, j) => {
                          return (
                            <div className="listOfPrefDiv" key={j}>
                              {`${y.origin}, ${y.origin_state} - ${y.dest}, ${y.dest_state}`}
                            </div>
                          );
                        })}
                        <div className="carrierComments">
                          <h1>
                            Logbook{" "}
                            <i
                              onClick={() => (
                                togglePopUp(17), changeSingleCarrier(x)
                              )}
                              className="fas fa-marker"
                            ></i>
                          </h1>
                          {x.notes.length === 0 ? (
                            <h1 style={{ fontSize: "0.9vw" }}>No records</h1>
                          ) : (
                            x.notes.map((z, k) => {
                              return (
                                <div className="singleComment" key={k}>
                                  <div className="commentAuthor">{`${z.author} - ${z.date}`}</div>
                                  <div className="commentContent">{z.content}</div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
                : dest !== ""
                  ? carrierList.map((x, i) => {
                    if (
                      x.preferred.filter(
                        y => y.dest === dest || y.dest_state === dest
                      ).length > 0
                    ) {
                      return (
                        <div
                          className={
                            x.rating === 1
                              ? "actualCarrier danger"
                              : x.rating === 2
                                ? "actualCarrier warning"
                                : "actualCarrier normalCarrier"
                          }
                          key={i}
                        >
                          <div className="titleSectionDivCarrier">
                            <div className="titleActualLoad">
                              <span style={{ fontSize: "1.2vw" }}>{`${x.name} (${x.rating === 0 ? "NA" : x.rating
                                })`}</span>
                              <br />
                              <span
                                style={{ fontSize: "0.9vw", fontWeight: "400" }}
                              >{`MC: ${x.mc}`}</span>
                            </div>
                            <div className="btns4Carrier">
                              <div className="titleActualLoad loadsBooked">
                                {`${x.loads.length} Loads Booked`}
                              </div>
                              <div
                                className="titleActualLoad editCarrierBtn"
                                onClick={() => (
                                  togglePopUp(20), changeSingleCarrier(x)
                                )}
                              >
                                Edit
                              </div>
                            </div>
                          </div>
                          {x.dispatchers.map((y, j) => {
                            return (
                              <div className="listOfContactsDiv" key={j}>
                                <div className="firstAttribute">{y.name}</div>
                                <div className="secondAttribute">{y.phone}</div>
                                <div className="secondAttribute">{y.email}</div>
                              </div>
                            );
                          })}
                          {x.preferred.map((y, j) => {
                            return (
                              <div className="listOfPrefDiv" key={j}>
                                {`${y.origin}, ${y.origin_state} - ${y.dest}, ${y.dest_state}`}
                              </div>
                            );
                          })}
                          <div className="carrierComments">
                            <h1>
                              Logbook{" "}
                              <i
                                onClick={() => (
                                  togglePopUp(17), changeSingleCarrier(x)
                                )}
                                className="fas fa-marker"
                              ></i>
                            </h1>
                            {x.notes.length === 0 ? (
                              <h1 style={{ fontSize: "0.9vw" }}>No records</h1>
                            ) : (
                              x.notes.map((z, k) => {
                                return (
                                  <div className="singleComment" key={k}>
                                    <div className="commentAuthor">{`${z.author} - ${z.date}`}</div>
                                    <div className="commentContent">{z.content}</div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                  : carrierList.map((x, i) => {
                    return (
                      <div
                        className={
                          x.rating === 1
                            ? "actualCarrier danger"
                            : x.rating === 2
                              ? "actualCarrier warning"
                              : "actualCarrier normalCarrier"
                        }
                        key={i}
                      >
                        <div className="titleSectionDivCarrier">
                          <div className="titleActualLoad">
                            <span style={{ fontSize: "1.2vw" }}>{`${x.name} (${x.rating === 0 ? "NA" : x.rating
                              })`}</span>
                            <br />
                            <span
                              style={{ fontSize: "0.9vw", fontWeight: "400" }}
                            >{`MC: ${x.mc}`}</span>
                          </div>
                          <div className="btns4Carrier">
                            <div className="titleActualLoad loadsBooked">
                              {`${x.loads.length} Loads Booked`}
                            </div>
                            <div
                              className="titleActualLoad editCarrierBtn"
                              onClick={() => (
                                togglePopUp(20), changeSingleCarrier(x)
                              )}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                        {x.dispatchers.map((y, j) => {
                          return (
                            <div className="listOfContactsDiv" key={j}>
                              {/* <div className="spaceActualLoad"></div> */}
                              <div className="firstAttribute">{y.name}</div>
                              <div className="secondAttribute">{y.phone}</div>
                              <div className="secondAttribute">{y.email}</div>
                            </div>
                          );
                        })}
                        {x.preferred.map((y, j) => {
                          return (
                            <div className="listOfPrefDiv" key={j}>
                              {`${y.origin}, ${y.origin_state} - ${y.dest}, ${y.dest_state}`}
                            </div>
                          );
                        })}
                        <div className="carrierComments">
                          <h1>
                            Logbook
                            <i
                              onClick={() => (
                                togglePopUp(17), changeSingleCarrier(x)
                              )}
                              className="fas fa-marker"
                            ></i>
                          </h1>
                          {x.notes.length === 0 ? (
                            <h1 style={{ fontSize: "0.9vw" }}>No records</h1>
                          ) : (
                            x.notes.map((z, k) => {
                              return (
                                <div className="singleComment" key={k}>
                                  <div className="commentAuthor">{`${z.author} - ${z.date}`}</div>
                                  <div className="commentContent">{z.content}</div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    );
                  })}
      </div>
    </React.Fragment>
  );
};

export default CarrierList;
