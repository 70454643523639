import React, { useContext, useEffect } from "react";
import LoadContext from "../context/loads/loadContext";
import AddOpenLoadPopUp from "./pop-ups/AddOpenLoadPopUp";
import RemoveLoadPopUp from "./pop-ups/RemoveLoadPopUp";
import MarkBookedPopUp from "./pop-ups/MarkBookedPopUp";
import SingleLoadPopUp from "./pop-ups/SingleLoadPopUp";
import CarrierWarning from "./pop-ups/CarrierWarning";
import DupLoadPopUp from "./pop-ups/DupLoadPopUp";
import CustomLabelPopUp from "./pop-ups/CustomLabelPopUp";

const OpenLoads = props => {
  const loadContext = useContext(LoadContext);
  const {
    addOpenLoadPopUp,
    togglePopUp,
    addOpenLoad,
    getOpenLoads,
    openLoadList,
    afterDeleteOpen,
    toggleRemovePopUp,
    deleteLoadPopUp,
    singleLoadId,
    removeOpenLoad,
    markScanned,
    markLoadBookedPopUp,
    toggleMarkBookedPopUp,
    markLoadBooked,
    markLoadBooked2,
    addLabel,
    editLoad,
    singleLoadPopUp,
    singleLoad,
    submitFeedback,
    changeSingleLoad,
    afterDeleteCurrent,
    carrierWarningPopUp,
    message,
    dupOpenLoad,
    dupPopUp,
    customLabelPopUp
  } = loadContext;

  useEffect(() => {
    getOpenLoads();
  }, [afterDeleteOpen, afterDeleteCurrent]);

  return (
    <React.Fragment>
      {addOpenLoadPopUp ? (
        <AddOpenLoadPopUp
          quote={false}
          toggle={togglePopUp}
          add={addOpenLoad}
        />
      ) : null}
      {deleteLoadPopUp ? (
        <RemoveLoadPopUp
          toggle={togglePopUp}
          id={singleLoadId}
          remove={removeOpenLoad}
        />
      ) : null}
      {dupPopUp ? (
        <DupLoadPopUp
          toggle={togglePopUp}
          id={singleLoadId}
          duplicate={dupOpenLoad}
          load={singleLoad}
        />
      ) : null}
      {markLoadBookedPopUp ? (
        <MarkBookedPopUp
          toggle={togglePopUp}
          id={singleLoadId}
          markBooked={markLoadBooked}
          markLoadBooked2={markLoadBooked2}
          message={message}
          warning={carrierWarningPopUp}
        />
      ) : null}
      {carrierWarningPopUp ? <CarrierWarning /> : null}
      {singleLoadPopUp ? (
        <SingleLoadPopUp
          toggle={togglePopUp}
          submitFeedback={submitFeedback}
          add={editLoad}
          load={singleLoad}
          open={true}
          quote={false}
          master={false}
          _admin={{ name: "nope" }}
          remove={removeOpenLoad}
          markScanned={markScanned}
          adminObj={props.adminObj}
          addLabel={addLabel}
          customLabelPopUp={customLabelPopUp}
        />
      ) : null}
      {customLabelPopUp ? (
        <CustomLabelPopUp
          toggle={togglePopUp}
          load={singleLoad}
          addLabel={addLabel}
        />
      ) : null}
      <div className="toolBarContent">
        {/* <div id="filter">
          <div>
            <i className="fas fa-filter searchIcon"></i>Filter
          </div>
        </div> */}
        {props.adminObj ? (
          props.adminObj.guest ? null : (
            <div id="addOpenLoad" onClick={() => togglePopUp(2)}>
              Add Open Load
            </div>
          )
        ) : null}
      </div>
      <div className="bigCurrentDiv">
        {openLoadList.length === 0 ? (
          <h1>No open loads</h1>
        ) : (
          openLoadList.map((x, i) => {
            return (
              <div
                key={i}
                onClick={() => (changeSingleLoad(x), togglePopUp(9))}
                className="actualLoad actualOpenLoad"
              >
                {x.label || x.g === "pending" ? (
                  x.label === "In Progress" || x.g === "pending" ? (
                    <div className="titleActualLoadWrapper">
                      <div className="titleActualLoadInner">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="titleActualLoadTag">
                        <span style={{ color: "#fff", backgroundColor: "#4271ff" }}>In Progress</span>
                      </div>
                    </div>
                  ) : x.label === "Hot" ? (
                    <div className="titleActualLoadWrapper">
                      <div className="titleActualLoadInner">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="titleActualLoadTag">
                        <span style={{ color: "#fff", backgroundColor: "#BF4949" }}>Hot</span>
                      </div>
                    </div>
                  ) : x.label === "On hold" ? (
                    <div className="titleActualLoadWrapper">
                      <div className="titleActualLoadInner">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="titleActualLoadTag">
                        <span style={{ color: "#fff", backgroundColor: "#808080" }}>On hold</span>
                      </div>
                    </div>
                  ) : x.label === "Not owned" ? (
                    <div className="titleActualLoadWrapper">
                      <div className="titleActualLoadInner">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="titleActualLoadTag">
                        <span style={{ color: "#fff", backgroundColor: "#000" }} >Not owned</span>
                      </div>
                    </div>
                  ) : x.label !== "" ? (
                    <div className="titleActualLoadWrapper">
                      <div className="titleActualLoadInner">
                        {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                      </div>
                      <div className="titleActualLoadTag">
                        <span style={{ color: "#fff", backgroundColor: "#BBC356" }} >{x.label}</span>
                      </div>
                    </div>
                  ) : (
                    <div className="titleActualLoad titleOpen">
                      {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                    </div>
                  )
                ) : (
                  <div className="titleActualLoad titleOpen">
                    {`${x.origin}, ${x.origin_state} - ${x.destination}, ${x.destination_state}`}
                  </div>
                )}
                <div
                  className={
                    props.adminObj
                      ? props.adminObj.guest
                        ? "firstAttribute firstAttOpen2"
                        : "firstAttribute firstAttOpen"
                      : null
                  }
                >
                  {`${x.pu_date_display} `} <span>{`- ${x.pu_time}`}</span>
                  <i className="fas fa-long-arrow-alt-right arrowIcon2"></i>
                  {`${x.do_date_display} `} <span>{`- ${x.do_time}`}</span>
                </div>
                <div className="secAttribute secOnOpen">
                  {`$${x.truck_rate} `}
                </div>
                {props.adminObj ? (
                  props.adminObj.guest ? null : (
                    <div className="fourthAttribute fourthOnOpen">
                      <div
                        onClick={() => (
                          toggleMarkBookedPopUp(x._id), togglePopUp(9)
                        )}
                        className="markBooked"
                      >
                        Mark Booked
                      </div>
                      <div
                        onClick={() => (
                          toggleRemovePopUp(x._id),
                          changeSingleLoad(x),
                          togglePopUp(9)
                        )}
                        className="markRemove"
                      >
                        Remove
                      </div>
                      <div
                        onClick={() => (
                          togglePopUp(14), togglePopUp(9), changeSingleLoad(x)
                        )}
                        className="dupBtn"
                      >
                        <i className="far fa-clone"></i>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            );
          })
        )}
      </div>
    </React.Fragment>
  );
};

export default OpenLoads;
