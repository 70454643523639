import React, { useContext, useEffect } from "react";
import LoadContext from "./context/loads/loadContext";
import AuthContext from "./context/auth/authContext";
import CurrentLoads from "./Content/CurrentLoads";
import OpenLoads from "./Content/OpenLoads";
import MasterList from "./Content/MasterList";
import CarrierList from "./Content/CarrierList";
import Staff from "./Content/Staff";
import Spinner from "./utilities/Spinner";
import NCLoads from "./Content/NCLoads";
import Quotes from "./Content/Quotes";
import MasterListNewYear2021 from "./Content/MasterListNewYear2021";
import MasterListNewYear2022 from "./Content/MasterListNewYear2022";
import MasterListNewYear2023 from "./Content/MasterListNewYear2023";
import MasterListNewYear2024 from "./Content/MasterListNewYear2024";
import MasterListNewYear2025 from "./Content/MasterListNewYear2025";

const Home = () => {
  const authContext = useContext(AuthContext);
  const { admin_access, loadUser, logoutUser, admin } = authContext;

  const loadContext = useContext(LoadContext);
  const { index, changeIndex, loading, clearFilter } = loadContext;

  useEffect(() => {
    if (admin_access) {
      loadUser();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [index]);

  return (
    <React.Fragment>
      {loading ? <Spinner /> : null}
      <div className="toolBarHome">
        {admin ? (
          admin.guest ? (
            <div id="RMtransGuest">
              RM Transportation
              <br />
              <br />
              <span>Guest Portal</span>
            </div>
          ) : (
            <div id="RMtrans">RM Transportation</div>
          )
        ) : null}
        <span></span>
        <span></span>
      </div>
      {admin ? (
        admin.guest ? (
          <div className="bigHomeDiv">
            <div className="leftIndexDiv">
              <div className="indexBtns">
                <div onClick={() => changeIndex(2)} className="currentIndex">
                  Open Loads
                </div>
                <div className="spaceForLogout"></div>
                <div onClick={logoutUser} className="defaultIndex">
                  Sign out
                </div>
              </div>
            </div>
            <div className="rightContent">
              <OpenLoads adminObj={admin} />
            </div>
          </div>
        ) : (
          <div className="bigHomeDiv">
            <div className="leftIndexDiv">
              <div className="indexBtns">
                <div
                  onClick={() => (clearFilter(), changeIndex(1))}
                  className={index === 1 ? "currentIndex" : "defaultIndex"}
                >
                  Current Loads
                </div>
                <div
                  onClick={() => changeIndex(2)}
                  className={index === 2 ? "currentIndex" : "defaultIndex"}
                >
                  Open Loads
                </div>
                <div
                  onClick={() => changeIndex(6)}
                  className={index === 6 ? "currentIndex" : "defaultIndex"}
                >
                  Nearly Complete Loads
                </div>
                <div
                  onClick={() => (clearFilter(), changeIndex(12))}
                  className={index === 3 || index === 8 || index === 9 || index === 10 || index === 11 || index === 12 ? "currentIndex" : "defaultIndex"}
                >
                  Master
                </div>
                <div
                  onClick={() => changeIndex(4)}
                  className={index === 4 ? "currentIndex" : "defaultIndex"}
                >
                  Carrier List
                </div>
                <div
                  onClick={() => (clearFilter(), changeIndex(7))}
                  className={index === 7 ? "currentIndex" : "defaultIndex"}
                >
                  Quotes
                </div>
                <div
                  onClick={() => changeIndex(5)}
                  className={index === 5 ? "currentIndex" : "defaultIndex"}
                >
                  Accounts
                </div>
                <div className="spaceForLogout"></div>
                <div onClick={logoutUser} className="defaultIndex">
                  Sign out
                </div>
              </div>
            </div>
            <div className="rightContent">
              {index === 1 ? (
                <CurrentLoads adminObj={admin} />
              ) : index === 2 ? (
                <OpenLoads adminObj={admin} />
              ) : index === 6 ? (
                <NCLoads adminObj={admin} />
              ) : index === 8 ? (
                <MasterListNewYear2021 adminObj={admin} />
              ) : index === 9 ? (
                <MasterListNewYear2022 adminObj={admin} />
              ) : index === 10 ? (
                <MasterListNewYear2023 adminObj={admin} />
              ) : index === 11 ? (
                <MasterListNewYear2024 adminObj={admin} />
              ) : index === 12 ? (
                <MasterListNewYear2025 adminObj={admin} />
              ) : index === 3 ? (
                <MasterList adminObj={admin} />
              ) : index === 4 ? (
                <CarrierList />
              ) : index === 7 ? (
                <Quotes adminObj={admin} />
              ) : index === 5 ? (
                <Staff />
              ) : null}
            </div>
          </div>
        )
      ) : null}
    </React.Fragment>
  );
};

export default Home;
